import React, {useState} from 'react'
import {textData, ajaxUpdate, partnerOpt} from './textData'
import {stringReplaceMask, phoneDraw, uSearch, tokenIncorrect, sSet, sGet, sDel, sName, eCheck, slGet} from 'helper-func'
import {apiBoAuthFormOrUrl, apiBoHistory, apiBoUserInfo, apiBoSubsInfo, apiBoSubsASR, apiBoUserSafe, apiBoUserInfoCheck, apiFrameGetDataCheck, apiBoHistoryCheck} from 'api-methods'
import {lpTextData} from 'static-data'

function BackOffice() {

    // 0. self
    const PARTNER_OPT                           = partnerOpt
    const [errorMessage, setErrorMessage]       = useState('')
    const [popup, setPopup]                     = useState(false)
    const [popupType, setPopupType]             = useState('form')
    const [popupItem, setPopupItem]             = useState(0)
    const [popupValue, setPopupValue]           = useState(0)
    const [popupMessage, setPopupMessage]       = useState('')
    const [popupAlert, setPopupAlert]           = useState('')
    const [internalRoute, setInternalRoute]     = useState('loader')
    const [reqAuth, setReqAuth]                 = useState(false)
    const [boUserData, setBoUserData]           = useState({})
    const [boProductData, setBoProductData]     = useState({})
    const [userEmail, setUserEmail]             = useState('')
    const [userPhone, setUserPhone]             = useState('')

    let ajPost
    let useItem             = {}
    let internalLogin       = sGet(sName.uBoLogin)
    let internalToken       = sGet(sName.uBoToken)
    let iTh                 = slGet('th', ['Violet','ARed','Magic','Siren','Sky'], PARTNER_OPT.th)
    let iLp                 = slGet('lp', ['ru','en'],  PARTNER_OPT.lp)

    function clearAndReload(apiCode){if(apiCode!==200){sDel(sName.uBoToken);sDel(sName.uBoLogin);window.location.reload()}}

    // 1. auth
    const [inputAuthLogin, setInputAuthLogin] = useState('')
    const [inputAuthPass, setInputAuthPass] = useState('')
    const [inputAuthErr, setInputAuthErr] = useState('')
    const authFormLogin = (props) => {setInputAuthErr('');setInputAuthLogin(props);}
    const authFormPass = (props) => {setInputAuthErr('');setInputAuthPass(props);}
    const authReqSend = async (nextAction = false) => {
        if (inputAuthLogin + '' === '' && !loginAuth()) {
            setInputAuthErr(lpTextData.uBo[iLp].popup.loginNo)
        } else if (inputAuthPass + '' === '' && !loginAuth()) {
            setInputAuthErr(lpTextData.uBo[iLp].popup.passwordNo)
        } else if (!reqAuth) {
            setReqAuth(true)
            if (loginAuth()) {
                if (internalToken === "" || internalToken === "error") {
                    ajPost = await apiBoAuthFormOrUrl('url', {})
                } else {
                    ajPost = {code: (internalToken === "error" ? -1 : 0), token: internalToken}
                }
            } else {
                ajPost = await apiBoAuthFormOrUrl('form', {login: inputAuthLogin, pass: inputAuthPass})
            }
            setReqAuth(false)
            if (ajPost.code + '' !== '0') {
                setInputAuthErr(lpTextData.uBo[iLp].popup.error + ': ' + ajPost.message + '')
                if (!loginAuth()) {
                    sSet(sName.uBoToken, "error")
                } else {
                    sDel(sName.uBoToken)
                    setErrorMessage(ajPost.message)
                }
            } else {
                sSet(sName.uBoToken, ajPost.token)
                sSet(sName.uBoLogin, inputAuthLogin)
                setInternalRoute('loader')
                if (nextAction) {
                    ajaxSearch(ajPost.token).then()
                }
            }
        }
    }
    function loginAuth () {return (uSearch('login') !== '' && uSearch('uuid') !== '' && uSearch('sign') !== '')}

    // 2. search
    const [inputSearchUser, setInputSearchUser] = useState('')
    const [inputSearchDog, setInputSearchDog] = useState('')
    const searchFormId = (props) => {setInputSearchUser(props);}
    const searchFormLogin = (props) => {setInputSearchDog(props);}
    const ajaxSearch = async (methodToken = '') => {
        if (
            ((textData.addSearchId && inputSearchUser + '' !== '') || inputSearchDog + '' !== '')
            && (
                ((textData.addSearchId && uSearch('id') === '') || uSearch('uuid') === '')
                || (textData.addSearchId && uSearch('id') !== inputSearchUser)
                || (uSearch('uuid') !== inputSearchDog)
            )
        ) {
            window.location.href = document.location.origin + document.location.pathname + '?' + (textData.addSearchId ? ('id=' + inputSearchUser + '&') : '') + 'uuid=' + inputSearchDog
        } else if ((!tokenIncorrect(internalToken) || methodToken !== '') && (uSearch('uuid') !== '' || (textData.addSearchId && uSearch('id') !== ''))) {
            ajPost = await apiBoUserInfo('uuid', 'id').then()
            if (ajPost.code === 401 || ajPost.code === 500) {
                clearAndReload(ajPost.code)
            } else {
                apiBoUserInfoCheck(ajPost).then()
                setBoUserData(ajPost.data.user)
                if (ajPost.data.user.email) {setUserEmail(ajPost.data.user.email)}
                if (ajPost.data.user.phone) {setUserPhone(ajPost.data.user.phone)}
                setInternalRoute('search')
                let ajPostTwo = await apiBoSubsInfo(ajPost.data.user.id)
                if (ajPostTwo.code === 0) {
                    apiFrameGetDataCheck(ajPostTwo).then()
                }
                if (!!ajPostTwo && ajPostTwo !== {} && !!ajPostTwo.data && !!ajPostTwo.data !== {}) {setBoProductData(ajPostTwo.data)}
            }
        }
    }

    // 2.5 all hist block hide
    function historyOperationHide () {
        let elemLength = document.getElementsByClassName('UniBOBody__userTableData-historyOperationRelative').length
        for (let i = 0, ii = elemLength; i < ii; i++) {
            document.getElementsByClassName('UniBOBody__userTableData-historyOperationRelative')[i].setAttribute('style', '')
        }
    }

    // 3. history
    async function historyOperations (subsId) {
        let setElementSelector = document.getElementById('productHistory_' + subsId)
        if (setElementSelector.getAttribute('style') + '' === 'null' || setElementSelector.getAttribute('style') + '' === '') {historyOperationHide()}

        ajPost = await apiBoHistory(subsId)
        setPopup(true)
        if (ajPost.code === 401 || ajPost.code === 500) {
            clearAndReload(ajPost.code)
        } else {
            if (ajPost.code === 0) {
                apiBoHistoryCheck(ajPost).then()
            }
            if (!!ajPost && ajPost !== {} && !!ajPost.data && ajPost.data !== {}) {
                setElementSelector.innerHTML = '<div class="histNoData">Нет данных</div>'
                setElementSelector.innerHTML = drawTable(ajPost.data, {id: subsId, name: document.getElementById('productHistoryName_' + subsId).getAttribute('name')})
                setElementSelector.setAttribute('style', 'display:block;')
                setPopup(true)
                setPopupType('hist')
            }
        }

        return ''
    }

    // 4. table
    const drawTable = (props, internalData) => {
        let outHtml = '<div class="popupHistoryOperations popupHistoryOperations'+iTh+'"><div># ' + internalData.id + ' - ' + internalData.name + '</div><div><table class="UniBO__HistTable"><thead><tr><td>№&nbsp;&nbsp;</td><td>'+ lpTextData.uBo[iLp].popup.tableHistDate +'</td><td>'+ lpTextData.uBo[iLp].popup.tableHistAction +'</td><td>'+ lpTextData.uBo[iLp].popup.tableHistInit +'</td></tr></thead><tbody>'
        if (props !== {}) {
            Object.keys(props).forEach((item, key) => {
                outHtml += '<tr><td>' + (1+key) + ' </td><td>' + (props[item].data ?? '-') + ' </td><td>' + (props[item].action ?? '-') + ' </td><td>' + (props[item].user ?? '-') + ' </td></tr>'
            })
        }
        outHtml += '</tbody></table></div></div>'
        return outHtml
    }

    // 5.1 flopProduct
    function flopProduct (prdId, active, disable) {
        historyOperationHide()
        if (disable){return;}
        setPopupItem(prdId)
        if (!active) {if (userEmail + '' === '' && userPhone + '' === '') {setPopupType('userData')} else {setPopupType('addSubs')}} else {setPopupType('delSubs')}
        setPopup(true)
    }

    // 5.2 productInSubs
    function productInSubs (prdId, active = false) {
        let match = false
        if (!!boProductData.subscriptions) {
            Object.keys(boProductData.subscriptions).forEach((item, i) => {
                if (prdId === boProductData.subscriptions[item].product.id) {
                    match = boProductData.subscriptions[item].id
                    if (active && boProductData.subscriptions[item].status !== 'active') {
                        match = false
                    }
                }
            })
        }
        return match
    }

    // 6. subsMove
    const [popupKeyActionLock, setPopupKeyActionLock] = useState(false)
    async function subsMove() {
        let reloadPage = true
        if (!popupKeyActionLock) {
            if (popupType === 'delSubs') {
                if ((popupValue === 0 || (popupValue === 5 && popupMessage === '')) && textData.unSubs) {
                    reloadPage = false
                    setPopupAlert(lpTextData.uBo[iLp].popup.variant5reason)
                } else {
                    setPopupType('loaderPopup')
                    setPopupKeyActionLock(true)
                    ajPost = await apiBoSubsASR('suspend', {numberAnswer: ((textData.unSubs) ? popupValue : '20'), phone: "", subscription: popupItem, textAnswer: popupMessage})
                }
            } else {
                setPopupType('loaderPopup')
                setPopupKeyActionLock(true)
                ajPost = await apiBoSubsASR((productInSubs(popupItem, false) ? 'resume' : 'activate'), productInSubs(popupItem, false) ? {subscription: productInSubs(popupItem, false)} : {product: popupItem, source: "backoffice", user_id: boUserData.id})
            }
        }
        if (ajPost.code === 401 || ajPost.code === 500) {
            clearAndReload(ajPost.code)
        }
        if (reloadPage && ajPost.code + '' === '0') {
            window.location.reload()
        } else {
            if (!!ajPost.code && ajPost.code + '' === '-12') {
                setPopupAlert(lpTextData.uBo[iLp].popup.addData)
                setPopupType('userData')
                setPopup(true)
            } else if (!!ajPost.message && ajPost.message) {
                setPopupType('errCode');setPopupMessage(ajPost.message);setPopup(true);historyOperationHide()
            } else {
                popupCloseAndClear()
            }
        }
    }

    // 7.1 search form
    const [formNoReDraw, setFormNoReDraw] = useState(false)
    if (!formNoReDraw) {setFormNoReDraw(true);setTimeout(() => {searchFormId(uSearch('id'));searchFormLogin(uSearch('uuid'))}, textData.ajaxUpdate)}
    function handleKeyDown (event) {if (event.keyCode === 13) {authReqSend()}}
    function userDataPopupForm (inputName, inputValue) {if (inputName === 'email') {setUserEmail(inputValue)} else if (inputName === 'phone') {setUserPhone(inputValue)}}

    // 8. log out, close, popup
    let popupList = ['hist','loaderPopup','addSubs','delSubs','userData','errCode']
    function ajaxLogout () {sDel(sName.uBoToken);window.location.reload()}
    function popupCloseAndClear () {if (popup) {setPopupType('form');setPopup(false);historyOperationHide();setPopupKeyActionLock(false)}}
    function setPopupValueFunc (value) {setPopupAlert('');setPopupValue(value)}
    function setPopupMessageFunc (value) {setPopupAlert('');setPopupMessage(value)}

    // 9. i'm here
    const [fastAuth, setFastAuth] = useState(false)
    if (internalRoute === 'loader') {
        if (loginAuth() && !fastAuth) {
            setFastAuth(true)
            authReqSend(true).then()
        } else if (tokenIncorrect(internalToken)) {
            setInternalRoute('loginForm')
        } else if (!loginAuth()) {
            setInternalRoute('search')
            ajaxSearch().then()
        }
    }

    // 10. Activate, Suspend, Resume
    async function userDataReSend (popupItem) {
        if (!eCheck(userEmail, 'email')) {
            setUserEmail('')
            setPopupAlert(lpTextData.uBo[iLp].popup.emailErr + ' !')
        } else if (stringReplaceMask(userPhone).length !== 11) {
            setUserPhone('')
            setPopupAlert(lpTextData.uBo[iLp].popup.phoneNo + ' !')
        } else {
            setPopupItem(popupItem)
            ajPost = await apiBoUserSafe({id: boUserData.id, email: userEmail, phone: userPhone})
            if (ajPost.code + '' !== '0') {
                if (!!ajPost.message) {
                    setPopupType('errCode');setPopupMessage(ajPost.message);setPopup(true);historyOperationHide()
                } else {
                    clearAndReload(ajPost.code)
                }
            } else {
                setPopupType('loaderPopup')
                ajPost = await apiBoSubsASR((productInSubs(popupItem, false) ? 'resume' : 'activate'), productInSubs(popupItem, false) ? {subscription: productInSubs(popupItem, false)} : {product: popupItem, source: "backoffice", user_id: boUserData.id})
                if (ajPost.code + '' !== '0') {
                    if (!!ajPost.message) {
                        setPopupType('errCode');setPopupMessage(ajPost.message);setPopup(true);historyOperationHide()
                    } else {
                        clearAndReload(ajPost.code)
                    }
                } else {
                    window.location.reload()
                }
            }
        }
    }

    // 11. clear, resize, ajaxUpdate
    const [resizeValue, setResizeValue] = useState(800)
    function heightSend () {
        let actualPageSize = document.body.clientHeight + 40
        if (resizeValue !== actualPageSize) {
            setResizeValue(actualPageSize)
            window.parent.postMessage({height: actualPageSize}, '*')
        }
    }
    const [resizeEvent, setResizeEvent] = useState(false)
    if (!resizeEvent) {
        setResizeEvent(true)
        window.addEventListener('resize', function(event) {heightSend()}, true)
    }
    setTimeout(() => {heightSend()}, ajaxUpdate)

    return (
        <div className="UniBOFull">
            {(popup && popupType && (popupList.indexOf(popupType) > -1)) && (<div className="popupBack" onClick={_ => {popupCloseAndClear()}} />)}
            {(popup && popupType && (popupList.indexOf(popupType) > -1)) && (<div className="popup"><div className="popupContentFull">
                <div className="popupContent popupShadow">
                    {
                        popupType === 'loaderPopup' && <div><br /><div className="UniBOHeader__title">{lpTextData.uBo[iLp].popup.titleExec}</div><br /><div className="preLoader"><div className="loader" /></div><br /><br />&nbsp;</div>
                    }
                    {
                        popupType === 'userData' && <div>
                            <br />
                            <div className="UniBOHeader__title">{lpTextData.uBo[iLp].popup.noData}</div>
                            <br />
                            <br />
                            {lpTextData.uBo[iLp].popup.email}:<br />
                            <input id="popupUserDataEmail" name="email" placeholder={lpTextData.uBo[iLp].popup.emailNo} value={userEmail} onChange={e => userDataPopupForm(e.target.name, e.target.value)} />
                            <br />
                            <br />
                            {lpTextData.uBo[iLp].popup.phone}:<br />
                            <input id="popupUserDataPhone" name="phone" placeholder={lpTextData.uBo[iLp].popup.phoneNo} value={phoneDraw(stringReplaceMask(userPhone))} onChange={e => userDataPopupForm(e.target.name, e.target.value)} />
                            <br />
                            <br />
                            {popupAlert !== '' && <div className="UniBO__mainRemoveSubs-alert">{popupAlert}</div>}
                            <br />
                            <div className="UniBOHeader__popupKeys">
                                <div className={"UniBO__mainButtonInvert UniBO__mainButtonInvert" + iTh} onClick={_ => {popupCloseAndClear()}}>{lpTextData.uBo[iLp].popup.close}</div>
                                <div className={"UniBO__mainButton UniBO__mainButton" + iTh} onClick={_ => {userDataReSend(popupItem)}}>{lpTextData.uBo[iLp].popup.buy}</div>
                            </div>
                            <br />
                        </div>
                    }
                    {
                        popupType === 'addSubs' && <div><br /><div className="UniBOHeader__title">{lpTextData.uBo[iLp].popup.addSubs}?</div><br /><div className="UniBOHeader__popupKeys"><div className={"UniBO__mainButtonInvert UniBO__mainButtonInvert" + iTh} onClick={_ => {popupCloseAndClear()}}>{lpTextData.uBo[iLp].no}</div><div className={"UniBO__mainButton UniBO__mainButton" + iTh} onClick={_ => {subsMove(popupItem)}}>{lpTextData.uBo[iLp].yes}</div></div><br /></div>
                    }
                    {
                        popupType === 'delSubs' && <div>
                            <br />
                            <div className="UniBOHeader__title">{lpTextData.uBo[iLp].popup.suspendSubs}?</div>
                            {
                                textData.unSubs && <div className="UniBO__mainRemoveSubs">
                                    <label><input name="removeSubs" type="radio" onChange={_ => setPopupValueFunc(1)} /> <div>{lpTextData.uBo[iLp].popup.variant1}</div></label>
                                    <label><input name="removeSubs" type="radio" onChange={_ => setPopupValueFunc(2)} /> <div>{lpTextData.uBo[iLp].popup.variant2}</div></label>
                                    <label><input name="removeSubs" type="radio" onChange={_ => setPopupValueFunc(3)} /> <div>{lpTextData.uBo[iLp].popup.variant3}</div></label>
                                    <label><input name="removeSubs" type="radio" onChange={_ => setPopupValueFunc(4)} /> <div>{lpTextData.uBo[iLp].popup.variant4}</div></label>
                                    <label><input name="removeSubs" type="radio" onChange={_ => setPopupValueFunc(5)} /> <div>{lpTextData.uBo[iLp].popup.variant5}</div></label>
                                    {popupValue === 5 && <div><textarea placeholder={lpTextData.uBo[iLp].popup.variant5reason} maxLength="250" onChange={e => setPopupMessageFunc(e.target.value)} /></div>}
                                    {popupAlert !== '' && <div className="UniBO__mainRemoveSubs-alert">{popupAlert}</div>}
                                </div>
                            }
                            <br />
                            <div className="UniBOHeader__popupKeys">
                                <div className={"UniBO__mainButtonInvert UniBO__mainButtonInvert" + iTh} onClick={_ => {popupCloseAndClear()}}>Нет</div>
                                <div className={"UniBO__mainButton UniBO__mainButton" + iTh} onClick={_ => {subsMove(popupItem)}}>Да</div>
                            </div>
                            <br />
                        </div>
                    }
                    {
                        popupType === 'errCode' && <div>
                            <br />
                            <div className="UniBOHeader__title">{lpTextData.uBo[iLp].popup.titleErr}</div>
                            {
                                <div className="UniBO__mainRemoveSubs">
                                    {popupMessage}
                                </div>
                            }
                            <br />
                            <div className="UniBOHeader__popupKeys">
                                <div className={"UniBO__mainButtonInvert UniBO__mainButtonInvert" + iTh} onClick={_ => {popupCloseAndClear()}}>{lpTextData.uBo[iLp].popup.close}</div>
                            </div>
                            <br />
                        </div>
                    }
                </div>
            </div></div>)}
            <div className={"UniBO" + iTh}>
                {(uSearch('sign') === '') && <div className="UniBOHeader">
                    <div className="UniBOHeader__logo UniBOHeader__logo-shift">
                        <div className="UniBOHeader__mainText">{lpTextData.uBo[iLp].panel.titleMain}</div>
                        <div className="UniBOHeader__authData-logoPass">{(internalRoute !== 'loginForm' && internalToken !== 'error') && ((internalLogin !=='') && (lpTextData.uBo[iLp].panel.user + ': ' + internalLogin))}</div>
                    </div>
                    <div className="UniBOHeader__authData">
                        {(internalRoute !== 'loginForm' && internalToken !== 'error') && <div className={"UniBOHeader__authData-exit UniBOHeader__authData-exit" + iTh}><div className={"UniBO__mainButton UniBO__mainButton"+iTh+" shadowLight"+iTh+" UniBO__minWidthKey"} onClick={_ => ajaxLogout()}>{lpTextData.uBo[iLp].exit}</div></div>}
                    </div>
                </div>}
                <div className="UniBOBody">
                    {
                        internalRoute === 'loader' && <div className="preLoader"><div className="loader" /></div>
                    }
                    {(!loginAuth() && (internalRoute === 'loginForm' || internalToken === 'error')) && (
                        <div className="UniBOBody__loginForm">
                            <div className="UniBOBody__loginForm-login"><input onKeyDown={event => handleKeyDown(event)} placeholder={lpTextData.uBo[iLp].popup.login} value={inputAuthLogin} onChange={e => authFormLogin(e.target.value)} /></div>
                            <div className="UniBOBody__loginForm-password"><input onKeyDown={event => handleKeyDown(event)} placeholder={lpTextData.uBo[iLp].popup.password} type="password" value={inputAuthPass} onChange={e => authFormPass(e.target.value)} /></div>
                            <div className="UniBOBody__loginForm-button"><div className={"UniBO__mainButton UniBO__mainButton"+iTh+" shadowLight" + iTh} onClick={_ => authReqSend()}>{lpTextData.uBo[iLp].popup.authorization}</div></div>
                            <div className="UniBOBody__loginForm-err">{(internalToken === 'error' && (inputAuthErr + '' !== '' && (inputAuthErr)))}</div>
                        </div>
                    )}
                    {((internalRoute === 'search' || errorMessage !== '') && internalToken !== 'error') && (
                        <div>
                            <div className="UniBOBody__mainBlock">
                                <div className="UniBOBody__leftBlock">
                                    {(uSearch('sign') === '') && <div>
                                        <div className="UniBOBody__titleBlock">{lpTextData.uBo[iLp].searchUser.titleMain}</div>
                                        <div className="UniBOBody__searchForm">
                                            {textData.addSearchId && <div className="aTest_searchFormId UniBOBody__searchForm-dog"><input placeholder={uSearch('id') ? (lpTextData.uBo[iLp].searchUser.userIdTitle + ' № ' + uSearch('id')) : lpTextData.uBo[iLp].searchUser.userIdPlace} value={inputSearchUser} onChange={e => searchFormId(e.target.value)} /></div>}
                                            <div className="aTest_searchFormUuid UniBOBody__searchForm-dog"><input placeholder={uSearch('uuid') ? (lpTextData.uBo[iLp].searchUser.userUuidTitle + ' № ' + uSearch('uuid')) : lpTextData.uBo[iLp].searchUser.userUuidPlace} value={inputSearchDog} onChange={e => searchFormLogin(e.target.value.replace(/[^0-9A-Za-z/]+/g, ""))} /></div>
                                            <br />
                                            <div className="UniBOBody__searchForm-button"><div className={"UniBO__mainButton UniBO__mainButton"+iTh+" shadowLight" + iTh} onClick={_ => ajaxSearch()}>{lpTextData.uBo[iLp].searchUser.search}</div></div>
                                        </div>
                                    </div>}
                                    {(uSearch('uuid') !== '' && boUserData !== {}) && (
                                        <div>
                                            <div className={'UniBOBody__titleBlock' + ((uSearch('sign') === '') ? ' UniBOBody__brakeBlock': '')}>{lpTextData.uBo[iLp].searchUser.titleUser}</div>
                                            <div className="UniBOBody__userData">
                                                {boUserData.id && <div className="UniBOBody__userData"><div>ID:</div><div>{boUserData.id}</div></div>}
                                                {boUserData.lastName && <div className="UniBOBody__userData"><div>{lpTextData.uBo[iLp].searchUser.family}:</div><div>{boUserData.lastName}</div></div>}
                                                {boUserData.firstName && <div className="UniBOBody__userData"><div>{lpTextData.uBo[iLp].searchUser.name}:</div><div>{boUserData.firstName}</div></div>}
                                                {uSearch('uuid') && <div className="UniBOBody__userData"><div>{lpTextData.uBo[iLp].searchUser.doc}:</div><div>{uSearch('uuid')}</div></div>}
                                                {boUserData.email && <div className="UniBOBody__userData"><div>{lpTextData.uBo[iLp].searchUser.email}:</div><div>{boUserData.email}</div></div>}
                                                {boUserData.phone && <div className="UniBOBody__userData"><div>{lpTextData.uBo[iLp].searchUser.phone}:</div><div>{phoneDraw(stringReplaceMask(boUserData.phone))}</div></div>}
                                                {(['','0','undefined'].indexOf(boUserData.balance + '') === -1) && <div className="UniBOBody__userData"><div>{lpTextData.uBo[iLp].searchUser.balance}:</div><div>{boUserData.balance}</div></div>}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="UniBOBody__rightBlock">
                                    {errorMessage === ''
                                        ? (<div>
                                            {(uSearch('uuid') !== '' && boProductData && boProductData.subscriptions && Object.keys(boProductData.subscriptions).length > 0) && (
                                                <div>
                                                    <div className="UniBOBody__titleBlock">{lpTextData.uBo[iLp].subsList.titleMain}</div>
                                                    <div className="UniBOBody__userTableData">
                                                        <table>
                                                            <thead>
                                                            <tr>
                                                                <td>{lpTextData.uBo[iLp].subsList.tableProduct}</td>
                                                                <td />
                                                                <td className="UniBO__textCenter">{lpTextData.uBo[iLp].subsList.tableStatus}</td>
                                                                <td>{lpTextData.uBo[iLp].subsList.tableConnectOn}</td>
                                                                <td dangerouslySetInnerHTML={{ __html: lpTextData.uBo[iLp].subsList.tableConnectOff }} />
                                                                <td className="UniBO__textCenter">{lpTextData.uBo[iLp].subsList.tableAction}</td>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {Object.keys(boProductData.subscriptions).length > 0 ? (
                                                                Object.keys(boProductData.subscriptions).map((item, i) => {
                                                                    const useItem = boProductData.subscriptions[item]
                                                                    useItem.date_create = !!useItem.date_create ? useItem.date_create.split(' ')[0] : ''
                                                                    useItem.date_active_to = !!useItem.date_active_to ? useItem.date_active_to.split(' ')[0] : ''
                                                                    return <tr key={i} s_id={boProductData.subscriptions[item].id}>
                                                                        <td>
                                                                            {
                                                                                useItem.product.cover && <div className="UniBOBody__userTableData-coverBlock" id={'productHistoryName_' + boProductData.subscriptions[item].id} name={useItem.product.name}>
                                                                                    <img className="UniBOBody__userTableData-cover" src={useItem.product.cover} alt={useItem.product.id}/>
                                                                                    <img className="UniBOBody__userTableData-coverBig" src={useItem.product.cover} alt={useItem.product.id} />
                                                                                </div>
                                                                            }
                                                                            <div className="UniBOBody__userTableData-afterCoverBlock">{useItem.product.name_short} {useItem.is_hit + '' === '1' && <b>({lpTextData.uBo[iLp].subsList.tableHit})</b>}</div>
                                                                        </td>
                                                                        <td>
                                                                            <div className={"UniBOBody__userTableData-historyOperation UniBOBody__userTableData-historyOperation" + iTh} onClick={_ => historyOperations(boProductData.subscriptions[item].id)} dangerouslySetInnerHTML={{ __html: lpTextData.uBo[iLp].subsList.tableHistory }}></div>
                                                                            <div id={'productHistory_' + boProductData.subscriptions[item].id} className="UniBOBody__userTableData-historyOperationRelative">&nbsp;</div>
                                                                        </td>
                                                                        <td className="UniBO__textCenter">{useItem.status_name}</td>
                                                                        <td className="UniBO__textCenter">{useItem.date_create}</td>
                                                                        <td className="UniBO__textCenter">{useItem.date_active_to}</td>
                                                                        <td>
                                                                            <div
                                                                                title={useItem.product.auto_prolong === 0 ? lpTextData.uBo[iLp].subsList.tableAutoProlong : ''}
                                                                                className={'UniBO__minWidthKey shadowLight'+iTh+' ' + ((useItem.status === 'active' || useItem.status === 'fin_block') ? ('UniBO__mainButtonInvert UniBO__mainButtonInvert'+iTh+' ') : 'UniBO__mainButton UniBO__mainButton'+iTh+' ') + ((useItem.product.auto_prolong === 0 || useItem.status === 'fin_block') ? 'UniBO__mainButton-disable' : '')}
                                                                                onClick={() => flopProduct(((useItem.status === 'active' || useItem.status === 'fin_block') ? boProductData.subscriptions[item].id : useItem.product.id), (useItem.status === 'active' || useItem.status === 'fin_block'), (useItem.product.auto_prolong === 0))}
                                                                            >
                                                                                {(useItem.status === 'active' || useItem.status === 'fin_block') ? lpTextData.uBo[iLp].subsList.tableClose : lpTextData.uBo[iLp].subsList.tableOpen}
                                                                            </div>
                                                                            {
                                                                                useItem.status === 'fin_block' && <div className={"UniBO__minWidthKey shadowLight"+iTh+" UniBO__mainButton"+iTh+" UniBO__mainButton-disable"} onClick={() => flopProduct(useItem.product.id, false, (useItem.product.auto_prolong === 0))}>{lpTextData.uBo[iLp].subsList.tableOpen}</div>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                })
                                                            ) : <tr><td colSpan="6">{lpTextData.uBo[iLp].subsList.tableNoSubs}</td></tr>}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            )}
                                            {(uSearch('uuid') !== '' && boProductData && boProductData.products && Object.keys(boProductData.products).length > 0) ? (
                                                <div>
                                                    <div className={'UniBOBody__titleBlock ' + (Object.keys(boProductData.subscriptions).length && 'UniBOBody__brakeBlock')}>{lpTextData.uBo[iLp].itemList.titleMain}</div>
                                                    <div className="UniBOBody__userTableData">
                                                        <table>
                                                            <thead><tr><td>{lpTextData.uBo[iLp].itemList.tableProduct}</td><td dangerouslySetInnerHTML={{ __html: lpTextData.uBo[iLp].itemList.tableTrial }} /><td dangerouslySetInnerHTML={{ __html: lpTextData.uBo[iLp].itemList.tablePrice + '&nbsp;(&#'+PARTNER_OPT.moneyChar+';)' }} />
                                                                <td className="UniBO__textCenter">{lpTextData.uBo[iLp].itemList.tableAction}</td>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {Object.keys(boProductData.products).length > 0 ? (
                                                                Object.keys(boProductData.products).map((item, i) => {
                                                                    useItem = boProductData.products[item]
                                                                    useItem.real_price = parseInt(useItem.real_price * 100) / 100
                                                                    if (useItem.is_show === 1 && textData.blockDistributor.indexOf(boProductData.products[item].distributor + '') === -1) {
                                                                        return <tr key={i}>
                                                                            <td>{useItem.cover && <div className="UniBOBody__userTableData-coverBlock"><img className="UniBOBody__userTableData-cover" src={useItem.cover}  alt={useItem.id}/><img className="UniBOBody__userTableData-coverBig" src={useItem.cover} alt={useItem.id} /></div>}{useItem.name_short} {useItem.is_hit + '' === '1' && <b>({lpTextData.uBo[iLp].itemList.tableHit})</b>}</td>
                                                                            <td className="UniBO__textCenter">{useItem.is_trial_product === 1 ? useItem.trial_days : '-'}</td>
                                                                            <td className="UniBO__textCenter">{useItem.real_price}</td>
                                                                            <td><div className={'UniBO__minWidthKey shadowLight'+iTh+' ' + (productInSubs(boProductData.products[item].id) ? ('UniBO__mainButtonInvert UniBO__mainButtonInvert'+iTh+' UniBOBody__disabled ') : ('UniBO__mainButton UniBO__mainButton'+iTh+' '))} onClick={_ => (flopProduct(boProductData.products[item].id, false, !!productInSubs(boProductData.products[item].id)))}>{lpTextData.uBo[iLp].itemList.tableAdd}</div></td>
                                                                        </tr>
                                                                    }
                                                                    return null
                                                                })
                                                            ) : <tr><td colSpan="4">{lpTextData.uBo[iLp].itemList.tableNoProducts}</td></tr>}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            ) : lpTextData.uBo[iLp].noData}
                                        </div>)
                                        : (<div className="UniBOFooter__errorMessage">
                                            <div className={"UniBOFooter__errorMessage-symbol UniBOFooter__errorMessage-symbol" + iTh}>!</div>
                                            <div className="UniBOFooter__errorMessage-head">{lpTextData.uBo[iLp].err.head}</div>
                                            <div className="UniBOFooter__errorMessage-title">{lpTextData.uBo[iLp].err.title}</div>
                                            <br/>
                                            <div className="UniBOFooter__errorMessage-message">{errorMessage}</div>
                                            <br/>
                                            <a href={document.location.href} className={"UniBO__mainButton UniBO__mainButton" + iTh}>{lpTextData.uBo[iLp].err.refresh}</a>
                                        </div>)
                                    }
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="UniBOFooter"><div className="UniBOFooter__poweredBy">{lpTextData.uBo[iLp].footer.poweredBy} <a href="https://enaza.ru/">Enaza</a>, {new Date().getFullYear()}</div><div className="UniBOFooter__support">{lpTextData.uBo[iLp].footer.support} <a href="mailto:help@enaza.ru">help@enaza.ru</a></div></div>
            </div>
        </div>
    )
}

export default BackOffice
