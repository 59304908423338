import React, {useState} from "react"
import {ajaxUpdate, filterBrandProducts, langTextData, partnerOpt} from './textData'
import {intLp, sSet, sDel, sGet, showOsTextInExtProps, ePrOs, ePrNoOs, phoneDraw, stringReplaceMask, uSearch, eCheck, slGet, eIcon, textDuration, productNameClearAndAddDevice, textHtmlClear, changeThemeHit, fStaticUrl} from 'helper-func'
import {apiFrameAuthToken, apiFrameGetData, apiFrameUserSafe, apiFrameSubsASR, apiFrameGetDataCheck, apiFrameGetDataSubs, apiFrameGetDataSubsCheck} from 'api-methods'
import {lpTextData} from 'static-data'

function App() {

    const PARTNER_OPT               = partnerOpt
    let iTh                         = slGet('th', ['Violet','ARed','Magic','Siren','Sky'], PARTNER_OPT.th)
    let iLp                         = slGet('lp', ['ru','en'],  PARTNER_OPT.lp)
    let storageTokenKey             = 'auth_tokens'
    let tempToken                   = {}
    let filterBlockPropertiesDraw   = []
    let textInternalMessage         = langTextData[PARTNER_OPT.lp].textInternal
    let isStatic                    = ((fStaticUrl).indexOf(document.location.pathname) > -1 || (document.location.search).indexOf('uuid') === -1) && PARTNER_OPT.showcase
    let internalToken               = sGet(storageTokenKey, true)[uSearch('uuid')] ?? ''
    let itemForHiddenAutoExtProp    = [12858610,12858612]

    const getTime = () => {         return (new Date).getTime()}
    const getTimeLock = (addTime = 0) => {     return getTime() + ajaxUpdate + addTime}
    const [objectLoad, setObjectLoad] = useState(getTime())
    const [timeLock, setTimeLock]   = useState(getTime())
    const [subsLock, setSubsLock]   = useState(getTime())
    const [popup, setPopup]         = useState(false)//false//true
    const [popupType, setPopupType] = useState('form')//form//load//err//ok
    const [reqAuth, setReqAuth]     = useState(false)
    const [reqData, setReqData]     = useState(false)
    const [loader, setLoader]       = useState(true)
    const [authErr, setAuthErr]     = useState(false)
    const [textData, setTextData]   = useState([])

    // 1.1. иконки ↓
    const CardProductIconProperties = (props) => {
        if (props.icon && (eIcon.noShowI).indexOf(props.icon) === -1) {
            return <div className="icon-and-text"><div className={(eIcon.osI[props.icon] ? eIcon.osI[props.icon].icon : eIcon.propI[props.icon].icon) + ' '} />
                {!props.short && <div className="icon-title-short">{eIcon.propI[props.icon][iLp] && eIcon.propI[props.icon][iLp]}</div>}
            </div>
        }
        return null
    }
    const CardProductIconExtProps = (props) => {
        return <div className="icon-and-text"><div className={'icon-icons-' + (props.license === 1 ? '07' : '') + (props.license === 2 ? '08' : '') + (props.license === 3 ? '09' : '')  + (props.license === 5 ? '10' : '')} />
            <div className="icon-title-short">
                {lpTextData.uFrame[iLp].extPropDevices.protectFrom} {props.license}&nbsp;{lpTextData.uFrame[iLp].extPropDevices.protectDevices}{props.license === 1 && lpTextData.uFrame[iLp].extPropDevices.protectDevicesOne}{(props.license > 1 && props.license < 5) && lpTextData.uFrame[iLp].extPropDevices.protectDevicesAll}{props.name === 'Dr.Web Премиум' && (' + ' + lpTextData.uFrame[iLp].extPropDevices.drWeb.splitTo)}
            </div>
        </div>
    }
    const CardProductIconOsProps = (props) => {
        return <div className="icon-and-text"><div className={'icon-icons-11'} />
            <div className="icon-title-short">{lpTextData.uFrame[iLp].extPropDevices.protectDevicesAllPlatform} {showOsTextInExtProps(props.os)}</div>
        </div>
    }
    // 1.1. иконки ↑

    // 1.2. service function (scroll, action - activate/suspend/resume, product in subs) ↓
    function scrollToTopInsideFrame () {
        document.getElementsByTagName("body")[0].scrollIntoView()
    }
    function subscriptionWork (typeBlock, product, action) {
        if (isStatic) {
            window.location.href = PARTNER_OPT.lk
        } else {
            document.getElementsByTagName("body")[0].scrollIntoView()
            setPopup(!popup)
            let keyAction = {
                activate: lpTextData.uFrame[iLp].action.activate,
                suspend: lpTextData.uFrame[iLp].action.suspend,
                resume: lpTextData.uFrame[iLp].action.resume
            }
            let tmpName = (product.name).split(' (')
            if (tmpName[1]) {tmpName[1] = tmpName[1].split(' ').join('&nbsp;')}
            tmpName = tmpName.join(' (')
            product.name = tmpName
            let tmpData = {
                name: product.name,
                trial: product.trial_days,
                price: (product.real_price + '').split('.00')[0],
                period: textDuration(product.duration),
                keyText: keyAction[action],
                cover: product.cover,
                action: action,
                product: product.id
            }
            setAddFormTel(PARTNER_OPT.tl)
            setAddFormEmail('')
            setAddFormErr('')
            setPopupData(tmpData)
        }
    }
    function comboSearch (itemId) {
        let combo = false;
        (textData && textData.data && textData.data.subscriptions && Object.keys(textData.data.subscriptions).length > 0) && (
            Object.keys(textData.data.subscriptions).forEach((item, i) => {
                if (itemId === textData.data.subscriptions[item].subscription_product) {
                    combo = true
                }
            })
        )
        return combo
    }
    // 1.2. service function (scroll, action - activate/suspend/resume, product in subs) ↑

    // 2.1. ajax - init ↓
    let ajaxInit = {
        bid: 1,
        brand: 1,
        category: 'antivirus',
        design: 2,
        rnd: uSearch('rnd'),
        sign: uSearch('sign'),
        time: uSearch('time'),
        uuid: uSearch('uuid'),
        token: internalToken
    }
    // 2.1. ajax - init ↑

    // 2.2. ajax - auth ↓
    const ajaxAuth = async () => {
        try {
            if (uSearch('uuid')) {
                setReqData(false)
                let ajPost = await apiFrameAuthToken(PARTNER_OPT.auth).then()
                if (!ajPost || !ajPost.token) {
                    setLoader(false)
                    setAuthErr(true)
                } else {
                    tempToken = sGet(storageTokenKey, true)
                    if (tempToken === null) {
                        tempToken = {}
                    }
                    tempToken[uSearch('uuid')] = ajPost.token
                    sSet(storageTokenKey, JSON.stringify(tempToken))
                    internalToken = ajPost.token
                    if (!reqData) {
                        setReqData(true)
                        ajaxGetData({token: ajPost.token})
                    }
                }
            }
        } catch (e) {}
    }
    // 2.2. ajax - auth ↑

    // 2.3. ajax - getData ↓
    const ajaxGetData = async (props) => {
        try {
            if (uSearch('uuid') || isStatic) {
                if (props.token + '' !== '' || isStatic) {
                    let ajPost = await apiFrameGetData(isStatic, props.token, PARTNER_OPT.auth).then()
                    if (!ajPost || ajPost.code !== 0) {
                        sDel(storageTokenKey)
                        setReqData(false)
                        setReqAuth(false)
                        setLoader(true)
                        if (!isStatic) {location.reload()}
                    } else {
                        apiFrameGetDataCheck(ajPost).then()
                        if (ajPost.data) {
                            if (ajPost.data.products) {
                                filterBrandProducts.forEach((filterItem) => {
                                    Object.keys(ajPost.data.products).forEach((item) => {
                                        if (ajPost.data.products[item].distributor + '' === filterItem + '') {
                                            if (filterBlockPropertiesDraw.length === 0 || filterBlockPropertiesDraw.indexOf(filterItem) === -1) {
                                                filterBlockPropertiesDraw.push(filterItem)
                                            }
                                        }
                                    })
                                })
                            }
                            if (ajPost.data.subscriptions) {
                                let tmpSubsReCheck = {}
                                let tmpSubsReId = {}
                                Object.keys(ajPost.data.subscriptions).forEach(subsItem => {
                                    if (ajPost.data.subscriptions[subsItem].status === 'active' && ajPost.data.subscriptions[subsItem].product.distributor === 387 && !ajPost.data.subscriptions[subsItem].shipmentData.key) {
                                        tmpSubsReId = 'p' + ajPost.data.subscriptions[subsItem].id
                                        tmpSubsReCheck[tmpSubsReId] = {id: ajPost.data.subscriptions[subsItem].id, reqCount: 1, reqTime: getTime()}
                                    }
                                })
                                if (subsLock <= getTime()) {
                                    setSubsLock(getTimeLock(5*1000))
                                    setInterval(() => {
                                        if (Object.keys(tmpSubsReCheck).length > 0) {
                                            Object.keys(tmpSubsReCheck).map(async item => {
                                                let maxTryReq = 4
                                                if (tmpSubsReCheck[item].reqCount < maxTryReq) {
                                                    tmpSubsReCheck[item].reqCount++
                                                    let ajPostSubs = await apiFrameGetDataSubs(props.token, tmpSubsReCheck[item].id).then()
                                                    apiFrameGetDataSubsCheck(ajPostSubs).then()
                                                    if (ajPostSubs && ajPostSubs.code === 0 && ajPostSubs.data && ajPostSubs.data['subscription'] && ajPostSubs.data['subscription'].shipmentData && Object.keys(ajPostSubs.data['subscription'].shipmentData).length > 1) {
                                                        tmpSubsReCheck[item].reqCount = maxTryReq
                                                        Object.keys(ajPost.data['subscriptions']).forEach(subsItem => {
                                                            // console.log(ajPost.data['subscriptions'][subsItem].id)
                                                            // console.log(ajPost.data['subscriptions'][subsItem].shipmentData)
                                                            // console.log(ajPostSubs.data['subscription'].id)
                                                            // console.log(ajPostSubs.data['subscription'].shipmentData)
                                                            if (ajPost.data['subscriptions'][subsItem].id === ajPostSubs.data['subscription'].id) {
                                                                ajPost.data['subscriptions'][subsItem].shipmentData = ajPostSubs.data['subscription'].shipmentData
                                                            }
                                                        })
                                                        setTextData(ajPost)
                                                        setTimeLock(getTime())
                                                    }
                                                }
                                            })
                                        }
                                    }, 5*1000)
                                }
                            }
                        }
                        ajPost.unique = filterBlockPropertiesDraw
                        setTextData(ajPost)

                        setLoader(false)
                        setObjectLoad(getTime())
                    }
                } else {
                    ajaxAuth(ajaxInit)
                }
            }
        } catch (e) {}
    }
    // 2.3. ajax - getData ↑

    // 0. main action ↓
    if (loader && !authErr) {
        if (timeLock <= getTime()) {
            setTimeLock(getTimeLock())
            if (internalToken + '' === '' && !isStatic) {
                if (!reqAuth) {
                    setReqAuth(true)
                    ajaxAuth(ajaxInit)
                }
            } else {
                if (!reqData) {
                    setReqData(true)
                    ajaxGetData({token: ajaxInit.token})
                }
            }
        }
    }
    // 0. main action ↑

    let popupDataStart = {cover: '',name: '',trial: false,price: '',period: '',keyText: lpTextData.uFrame[iLp].action.nextStep,action: ''}
    const [popupData, setPopupData] = useState(popupDataStart)
    const [popupDataCheck, setPopupDataCheck] = useState(true)
    const [addFormTel, setAddFormTel] = useState(PARTNER_OPT.tl)
    const [addFormEmail, setAddFormEmail] = useState('')
    const [addFormErr, setAddFormErr] = useState('')
    const [waitMessage, setWaitMessage] = useState({title: '', text: ''})
    const [forceReload, setForceReload] = useState(false)

    // 1.3. form check and save ↓
    const setFormTel = (tel) => {
        setAddFormErr('')
        setAddFormTel(phoneDraw(stringReplaceMask(tel)))
    }
    const setFormEmail = (email) => {
        setAddFormErr('')
        setAddFormEmail(email.replace(/[^A-Za-z0-9=\-@_.]+/g, ''))
    }
    function preSubsFormCheck(popupDataInternal){
        setWaitMessage({title: '', text: ''})
        setAddFormErr('')
        if (popupDataInternal.action === 'suspend') {
            ajaxUserSave(internalToken, ajaxInit)
        } else if (!eCheck(addFormEmail.toLowerCase())) {
            setAddFormErr(lpTextData.uFrame[iLp].preSubsCheck.errEmail)
        } else if (stringReplaceMask(addFormTel).length !== 11) {
            setAddFormErr(lpTextData.uFrame[iLp].preSubsCheck.errPhone)
        } else if (popupDataCheck !== true) {
            setAddFormErr(lpTextData.uFrame[iLp].preSubsCheck.errOffer)
        } else {
            ajaxUserSave(internalToken, ajaxInit, popupDataInternal)
        }
    }
    function preSuspFormCheck(){
        setWaitMessage({title: '', text: ''})
        setAddFormErr('')
        let suspendPrepareConfirm = false
        if (popupSuspendAns === 17 || popupSuspendAns === 19) {
            suspendPrepareConfirm = true
        } else if (popupSuspendAns === 18) {
            if (stringReplaceMask(addFormTel).length !== 11) {
                setAddFormErr(lpTextData.uFrame[iLp].preSubsCheck.errPhone)
            } else {
                suspendPrepareConfirm = true
            }
        } else if (popupSuspendAns === 20) {
            if (document.getElementsByName('message')[0].value.length === 0) {
                setAddFormErr(lpTextData.uFrame[iLp].preSubsCheck.errTitle)
            } else {
                suspendPrepareConfirm = true
            }
        }
        if (suspendPrepareConfirm) {
            setPopupData({action: 'load'})
            ajaxSubscription(popupSuspendData.token, popupSuspendData.ajaxInit, {action: 'suspend', subscription: popupSuspendData.popupDataInternal.subscription, numberAnswer: popupSuspendAns, phone: (popupSuspendAns === 18 ? addFormTel : ''), textAnswer: (popupSuspendAns === 20 ? document.getElementsByName('message')[0].value  : '')})
        }
    }
    // 1.3. form check and save ↑

    // 2.4. ajax - userSave ↓
    const ajaxUserSave = async (token, ajaxInit, popupDataInternal) => {
        setPopupType('load')
        try {
            let ajPost = await apiFrameUserSafe({token: token, phone: addFormTel, email: addFormEmail}).then()
            if (ajPost.code === 0) {
                ajaxSubscription(token, ajaxInit, popupDataInternal)
            } else if (ajPost.code === -3) {
                setAddFormErr(textInternalMessage.errorInternal)
                setPopupType('err')
            } else {
                setAddFormErr(ajPost.message)
                setPopupType('err')
            }
        } catch (e) {}
    }
    // 2.4. ajax - userSave ↑

    // 2.5. ajax - activate/suspend/resume ↓
    const [popupSuspendData, setPopupSuspendData] = useState({})
    const [popupSuspendAns, setPopupSuspendAns] = useState(0)
    const ajaxSubscriptionSuspend = (token, ajaxInit, popupDataInternal) => {
        if (PARTNER_OPT.askBeforeUnSubs) {
            setPopupSuspendData({token: token, ajaxInit: ajaxInit, popupDataInternal: popupDataInternal})
            setPopupSuspendAns(0)
            setPopupData({action: 'suspend', keyText: lpTextData.uFrame[iLp].action.suspend})
            setPopup(true)
        } else {
            ajaxSubscription(token, ajaxInit, popupDataInternal)
        }
    }
    const ajaxSubscription = async (token, ajaxInit, popupDataInternal) => {
        if (popupDataInternal.auto_prolong !== 0) {
            setPopupType('load')
            try {
                let ajJson = {}
                ajJson['token'] = token
                if (popupDataInternal.action === 'activate') {
                    ajJson['source'] = 'frame'
                    ajJson['product'] = popupDataInternal.product
                } else if (popupDataInternal.action === 'suspend') {
                    ajJson['subscription'] = popupDataInternal.subscription
                    ajJson['numberAnswer'] = 20
                    ajJson['phone'] = ''
                    ajJson['textAnswer'] = ''
                } else if (popupDataInternal.action === 'resume') {
                    ajJson['subscription'] = popupDataInternal.subscription
                }
                let ajPost = await apiFrameSubsASR(popupDataInternal.action, ajJson).then()
                if (ajPost.code === 0) {
                    if (popupDataInternal.action === 'activate') {
                        setWaitMessage({title: '' + popupDataInternal.name + ' ' + lpTextData.uFrame[iLp].afterSubs.successSubs, text: (textInternalMessage.successInternal + (((popupDataInternal.name).indexOf('PRO32') > -1) ? '<br /><br /><div>'+lpTextData.uFrame[iLp].afterSubs.successKeyWait+'</div>' : ''))})
                        setPopupType('ok')
                        setForceReload(true)
                    } else {
                        location.reload()
                    }
                } else if (ajPost.code === -3) {
                    setAddFormErr(textInternalMessage.errorInternal)
                    setPopupType('err')
                    setPopup(true)
                } else {
                    setAddFormErr(ajPost.message)
                    setPopupType('err')
                    setPopup(true)
                }
            } catch (e) {}
        }
    }
    // 2.5. ajax - activate/suspend/resume ↑

    // 1.4. clear, resize, ajaxUpdate ↓
    function popupCloseAndClear () {
        if (popup) {
            setPopupType('form')
            setPopupData(popupDataStart)
            setPopup(false)
            if (forceReload) {
                setForceReload(false)
                location.reload()
            }
        }
    }
    const [resizeValue, setResizeValue] = useState(800)
    function heightSend () {
        let actualPageSize = document.body.clientHeight + 40
        if (resizeValue !== actualPageSize) {
            setResizeValue(actualPageSize)
            window.parent.postMessage((PARTNER_OPT.height === 'short' ? actualPageSize : {height: actualPageSize}), '*')
        }
    }
    const [resizeEvent, setResizeEvent] = useState(false)
    if (!resizeEvent) {
        setResizeEvent(true)
        window.addEventListener('resize', function(event) {heightSend()}, true)
    }
    setTimeout(() => {heightSend()}, ajaxUpdate)
    // 1.4. clear, resize, ajaxUpdate ↑

    // 1.5. FormCard ↓
    const FrameProductCard = (props) => {
        let CardData = {}
        let CardDataSubs = {}
        if (props.typex === 'product') {
            CardData = textData.data.products[props.item]
        } else {
            CardData = textData.data.subscriptions[props.item].product
            CardDataSubs = textData.data.subscriptions[props.item]
        }
        if (PARTNER_OPT.priceDayToMonthHack && CardData.is_year + '' === '0') {
            CardData.real_price = Math.round(CardData.price * 30)
        }

        if ((props.typex === 'product' && !comboSearch(CardData.id)) || props.typex !== 'product') {
            return <div style={{zIndex: (10000 - props.num)}}>
                <div>
                    <div><img alt={CardData.name} src={CardData.cover} /></div>
                    <div>
                        <div>
                            <div dangerouslySetInnerHTML={{ __html: productNameClearAndAddDevice(CardData.name, CardData.license_count)}} />
                            <div>{ePrOs(CardData.ext_prop) && ePrOs(CardData.ext_prop).map((item, i) => {return <CardProductIconProperties key={i} icon={parseInt(item)} short={true} />})}</div>
                        </div>
                        {
                            (!props.typex === 'subs' || Object.keys(CardDataSubs).length === 0) ? <div/> : <div className="only_subsBlock">
                                <div>
                                    {CardDataSubs.status_name && <div><div className={((CardDataSubs.status === "active") ? "only_activate" : "only_suspend")} /><div>{CardDataSubs.status_name}</div></div>}
                                    {(CardDataSubs.date_create) && <div><br /><b>{lpTextData.uFrame[iLp].pCard.dCreate}:</b> {CardDataSubs.date_create}</div>}
                                    {(CardDataSubs.date_active_to) && <div><br /><b>{lpTextData.uFrame[iLp].pCard.dActive}:</b> {CardDataSubs.date_active_to}</div>}
                                    {(CardData.instructions && Object.keys(CardData.instructions).length > 0) && <div>
                                        <br />
                                        <b>{Object.keys(CardData.instructions).length === 1 ? lpTextData.uFrame[iLp].pCard.instruction : lpTextData.uFrame[iLp].pCard.instructions}:</b><br />
                                        {Object.keys(CardData.instructions).map((instItem, ii) => {return <div key={ii}><a target="_blank" href={CardData.instructions[instItem].value} >{lpTextData.uFrame[iLp].pCard.instructionInstall} {CardData.instructions[instItem].name}</a><br /></div>})}
                                    </div>}
                                </div>
                                <div>
                                    {(CardDataSubs.shipmentData && CardDataSubs.shipmentData.key) && <div><b>{lpTextData.uFrame[iLp].pCard.pCode}:</b><br />{CardDataSubs.shipmentData.key.value}<br />&nbsp;</div>}
                                    {(CardDataSubs.shipmentData && CardDataSubs.shipmentData.login) && <div><b>{lpTextData.uFrame[iLp].pCard.pLogin}:</b> {CardDataSubs.shipmentData.login.value}<br />&nbsp;</div>}
                                    {(CardDataSubs.shipmentData && CardDataSubs.shipmentData.pass) && <div><b>{lpTextData.uFrame[iLp].pCard.pPass}:</b> {CardDataSubs.shipmentData.pass.value}<br />&nbsp;</div>}
                                    {(CardDataSubs.shipmentData && !!CardDataSubs.product.distributor) && (
                                        (CardDataSubs.product.distributor === 387 && ((!CardDataSubs.shipmentData.distributive) || (CardDataSubs.shipmentData.distributive.length === 0)))
                                            ? <div>{lpTextData.uFrame[iLp].pCard.pKeyWait}</div>
                                            : <div><b>{!!CardDataSubs.shipmentData.distributive && !!CardDataSubs.shipmentData.distributive.length > 0 && lpTextData.uFrame[iLp].pCard.pProgram + ":"}</b><br />{!!CardDataSubs.shipmentData.distributive && CardDataSubs.shipmentData.distributive.map((distItem, di) => {return <div key={di}><a target="_blank" href={distItem.value} >{distItem.title}</a><br /></div>})}</div>
                                    )}
                                </div>
                            </div>
                        }
                        <div>
                            <div>
                                <div className={"only_" + props.typex} dangerouslySetInnerHTML={{ __html: textHtmlClear(CardData.announce)}} />
                                <div>
                                    {(CardData.ext_prop) && CardData.ext_prop.map((item, i) => {if (ePrNoOs(item)) {return <CardProductIconProperties key={i} icon={parseInt(item)} />;}return null})}
                                    {(CardData.distributor !== 356 && itemForHiddenAutoExtProp.indexOf(CardData.parent) === -1) && <CardProductIconOsProps os={CardData.ext_prop} />}
                                    {(CardData.license_count && CardData.distributor !== 356 && itemForHiddenAutoExtProp.indexOf(CardData.parent) === -1) > 0 && <CardProductIconExtProps license={CardData.license_count} name={CardData.name} />}
                                </div>
                            </div>
                            <div>
                                <div>{!CardData.is_trial_product ? <div><div>&nbsp;</div></div> : <div>{([30,60,90]).indexOf(CardData.trial_days) > -1 && <div>{intLp.iLpTrialDay[iLp].dayFreeL[CardData.trial_days]},<br />{intLp.iLpTrialDay[iLp].afterFreeL[CardData.trial_days]}</div>}</div>}</div>
                                <div>{(CardData.is_tarif && CardData.is_tarif + '' === '1') ? <div>{lpTextData.uFrame[iLp].pCard.inTariff}</div> : <div>{(CardData.real_price + '').split('.00')[0]} {PARTNER_OPT.moneyGlyph}{(CardData.distributor !== 356) && (textDuration((CardData.duration === 1 ? 30 : CardData.duration), true))}</div>}</div>
                                <div>
                                    {
                                        (props.typex === 'product')
                                            ? (
                                                comboSearch(CardData.id)
                                                    ? (CardData.auto_prolong !== 0 ? <a className={"keyControl " + "StyledKey_" + iTh} href={'#' + CardData.id} onClick={_ => scrollToTopInsideFrame()}>{lpTextData.uFrame[iLp].pCard.inControl}</a> : <div className={'keyControlDisabled keyControl StyledKey_' + iTh}>{lpTextData.uFrame[iLp].pCard.inControl}</div>)
                                                    : <div className={"keyConnect " + "StyledKey_" + iTh} onClick={_ => {subscriptionWork('product', CardData, 'activate')}}>{lpTextData.uFrame[iLp].pCard.flopSubs}</div>
                                            )
                                            : (
                                                ((!CardDataSubs.is_tarif || CardDataSubs.is_tarif + '' !== '1') && CardData.is_year + '' !== '1') && (
                                                    (CardDataSubs.status === 'active' || CardDataSubs.fin_block + '' === '1')
                                                        ? <div onClick={_ => {ajaxSubscriptionSuspend(internalToken, {}, {action: 'suspend', subscription: CardDataSubs.id, name:'', product:''})}} className={"keyConnect " + "StyledKeyOut_" + iTh}>{lpTextData.uFrame[iLp].pCard.flopSuspend}</div>
                                                        : (
                                                            (CardData.auto_prolong + '' !== '0' || CardData.distributor + '' !== '15')
                                                                ? <div onClick={_ => {ajaxSubscription(internalToken, {}, {action: 'resume', subscription: CardDataSubs.id, name:'', product:''})}} className={"keyConnect " + "StyledKey_" + iTh}>{lpTextData.uFrame[iLp].pCard.flopResume}</div>
                                                                : <div className="keyConnect StyledKeyOut_Grey">{lpTextData.uFrame[iLp].pCard.flopResume}</div>
                                                        )
                                                )
                                            )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        return null
    }
    const FrameProductHitCard = (props) => {
        let CardData = textData.data.products[props.item]
        if (PARTNER_OPT.priceDayToMonthHack && CardData.is_year + '' === '0') {
            CardData.real_price = Math.round(CardData.price * 30)
        }

        return <div style={{zIndex: (props.num)}}>
            <div className={"StyledHead_" + changeThemeHit(CardData)} />
            <div>
                <div>
                    <div><img alt={CardData.name} src={CardData.cover} /></div>
                    <div>
                        <div dangerouslySetInnerHTML={{ __html: productNameClearAndAddDevice(CardData.name, CardData.license_count, true)}} />
                        <div>
                            <div>{lpTextData.uFrame[iLp].pCard.pProgramFor}:</div>
                            <div>{ePrOs(CardData.ext_prop).map((item, i) => {return <CardProductIconProperties key={i} icon={parseInt(item)} short={true} />})}</div>
                        </div>
                        <div className="only_hit">
                            {(CardData.ext_prop) && CardData.ext_prop.map((item, i) => {if (ePrNoOs(item)) {return <CardProductIconProperties key={i} icon={parseInt(item)} />;}return null})}

                            <CardProductIconOsProps os={CardData.ext_prop} />
                            {(CardData.license_count > 0 && CardData.distributor !== 356) && <CardProductIconExtProps license={CardData.license_count} name={CardData.name} />}
                        </div>
                        <div>
                            <div>
                                <div>{!CardData.is_trial_product ? '' : <div>{([30,60,90]).indexOf(CardData.trial_days) > -1 && <div>{intLp.iLpTrialDay[iLp].dayFreeL[CardData.trial_days]},<br />{intLp.iLpTrialDay[iLp].afterFreeL[CardData.trial_days]}</div>}</div>}</div>
                            </div>
                            <div>
                                <div><div>{(CardData.is_tarif && CardData.is_tarif + '' === '1') ? <div>{lpTextData.uFrame[iLp].pCard.inTariff}</div> : <div>{(CardData.real_price + '').split('.00')[0]} {PARTNER_OPT.moneyGlyph}{(CardData.distributor !== 356) && (textDuration((CardData.duration === 1 ? 30 : CardData.duration), true))}</div>}</div></div>
                            </div>
                            <div>
                                {
                                    comboSearch(CardData.id)
                                        ? (CardData.auto_prolong !== 0 ? <a className={"keyControl " + "StyledKey_" + iTh} href={'#' + CardData.id} onClick={_ => scrollToTopInsideFrame()}>{lpTextData.uFrame[iLp].pCard.inControl}</a> : <div className={'keyControlDisabled keyControl StyledKey_' + iTh}>{lpTextData.uFrame[iLp].pCard.inControl}</div>)
                                        : <div className={"keyConnect " + "StyledKey_" + iTh} onClick={_ => {subscriptionWork('product', CardData, 'activate')}}>{lpTextData.uFrame[iLp].pCard.flopSubs}</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    // 1.5. FormCard ↑

    //mobile menu in showcase
    const [mMenu, setMMenu] = useState(false)

    return (<div className="App" load={objectLoad}>
        {loader ? <div className="preLoader"><div className="loader" /></div> : (
            authErr ? <div className="MainPage__width"><div className="MainPage__notFound">404</div></div> : <div>
                {popup && (<div className="popupBack" onClick={_ => {popupCloseAndClear()}} />)}
                {popup && (<div className="popup">
                    <div className="popupContentFull">
                        {(popupType === 'form') && (
                            (popupData.action !== 'suspend')
                                ? <div className="popupContent popupShadow">
                                    <div className="popupContent__top"><div className="popupContent__top-title"><b>{lpTextData.uFrame[iLp].popup.mainHeadTitle}</b></div><div className="popupContent__top-closes" onClick={_ => {popupCloseAndClear()}} >+</div></div>
                                    <div className="popupContent__product">
                                        <div className="popupContent__productTitle">
                                            <div className="popupContent__productTitle-icon  logoHitWidth"><img src={popupData.cover} alt={popupData.name} /></div>
                                            <div className="popupContent__productTitle-title" dangerouslySetInnerHTML={{__html: popupData.name}} />
                                        </div>
                                        <div className="popupContent__productPrice">
                                            {([30,60,90]).indexOf(popupData.trial) > -1 && <div className="popupContent__productPrice-before">{intLp.iLpTrialDay[iLp].dayFreeL[popupData.trial]}</div>}
                                            <div className="popupContent__productPrice-after">{([30,60,90]).indexOf(popupData.trial) > -1 && intLp.iLpTrialDay[iLp].afterFreeL[popupData.trial]} {popupData.price} {PARTNER_OPT.moneyGlyph}/{popupData.period}</div>
                                        </div>
                                    </div>
                                    <div className="popupContent__productComment">{lpTextData.uFrame[iLp].popup.mainTitle}</div>
                                    <div className="popupContent__productForm">
                                        <form onSubmit={_ => false}>
                                            <input type="text" name="email" value={addFormEmail} onChange={(e) => setFormEmail(e.target.value)} placeholder="Введите e-mail" />
                                            <input type="text" name="phone" value={addFormTel} onChange={(e) => setFormTel(e.target.value)} placeholder="" />
                                            <div className="popupContent__productForm-confirm" onClick={_ => {setAddFormErr('');setPopupDataCheck(!popupDataCheck)}}>
                                                <label>
                                                    <div className="checkboxborder"><div className={(popupDataCheck) ? 'checkboxborderInsideOn' : 'checkboxborderInsideOff' } /></div>
                                                    <div className="Filters__main-filterTitle" />
                                                </label>
                                                {lpTextData.uFrame[iLp].popup.inputOfferTitleBefore} <a href={(PARTNER_OPT.offerBottom.url.substring(0, 5) === 'https' ? '' : document.location.origin) + PARTNER_OPT.offerBottom.url} target="_blank">{lpTextData.uFrame[iLp].popup.inputOfferTitleAfter}</a>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="popupContent__productErr">{addFormErr !== '' && addFormErr}</div>
                                    <div className="popupContent__productConfirm" onClick={_ => preSubsFormCheck(popupData)}><div className={"keyConnect " + "StyledKey_" + iTh}>{popupData.keyText}</div></div>
                                </div>
                                : <div className="popupContent popupShadow">
                                    <div className="popupContent__top"><div className="popupContent__top-title"><b>{lpTextData.uFrame[iLp].popup.suspendTitle}</b></div><div className="popupContent__top-closes" onClick={_ => {popupCloseAndClear()}} >+</div></div>
                                    <div className="popupContent__productForm">
                                        <form onSubmit={_ => {false}} id="only_subsPopup">
                                            <div className="" onClick={_ => setPopupSuspendAns(17)}><label><div className="checkboxborder"><div className={(popupSuspendAns === 17) ? 'checkboxborderInsideOn' : 'checkboxborderInsideOff' } /></div><div className="Filters__main-filterTitle">{lpTextData.uFrame[iLp].popup.suspend17}</div></label></div>
                                            <div className="" onClick={_ => setPopupSuspendAns(18)}><label><div className="checkboxborder"><div className={(popupSuspendAns === 18) ? 'checkboxborderInsideOn' : 'checkboxborderInsideOff' } /></div><div className="Filters__main-filterTitle">{lpTextData.uFrame[iLp].popup.suspend18}</div></label></div>
                                            {popupSuspendAns === 18 && <div className=""><input type="text" name="phone" value={addFormTel} onChange={(e) => setFormTel(e.target.value)} placeholder="" /></div>}
                                            <div className="" onClick={_ => setPopupSuspendAns(19)}><label><div className="checkboxborder"><div className={(popupSuspendAns === 19) ? 'checkboxborderInsideOn' : 'checkboxborderInsideOff' } /></div><div className="Filters__main-filterTitle">{lpTextData.uFrame[iLp].popup.suspend19}</div></label></div>
                                            <div className="" onClick={_ => setPopupSuspendAns(20)}><label><div className="checkboxborder"><div className={(popupSuspendAns === 20) ? 'checkboxborderInsideOn' : 'checkboxborderInsideOff' } /></div><div className="Filters__main-filterTitle">{lpTextData.uFrame[iLp].popup.suspend20}</div></label></div>
                                            {popupSuspendAns === 20 && <div className=""><textarea maxLength="4000" name="message" /></div>}
                                        </form>
                                    </div>
                                    <div className="popupContent__productErr">{addFormErr !== '' && addFormErr}</div>
                                    <div className="popupContent__productConfirm" onClick={_ => preSuspFormCheck(popupData)}><div className={"keyConnect " + "StyledKey_" + iTh}>{popupData.keyText}</div></div>
                                </div>
                        )}
                    </div>

                    <div className="popupContentFull">
                        {(popupType === 'load') && (
                            <div className="popupContent popupShadow">
                                <div className="popupContent__top"><div className="popupContent__top-title"><b>{lpTextData.uFrame[iLp].popup.procSendTitle}</b></div><div className="popupContent__top-closes" onClick={_ => {popupCloseAndClear()}} >+</div></div>
                                <div className="popupContent__productErr LoaderMode"><div className="loader" /></div>
                                <br /><div dangerouslySetInnerHTML={{__html: " "}} />
                            </div>
                        )}
                    </div>

                    <div className="popupContentFull">
                        {(popupType === 'err') && (
                            <div className="popupContent popupShadow" onClick={_ => {location.reload()}}>
                                <div className="popupContent__top"><div className="popupContent__top-title"><b>{lpTextData.uFrame[iLp].popup.procReturnErr}</b></div><div className="popupContent__top-closes" onClick={_ => {popupCloseAndClear()}} >+</div></div>
                                <div className="popupContent__productErr">{addFormErr !== '' && (addFormErr)}</div>
                                <div className="popupContent__productConfirm ErrMode"><div className={"keyConnect " + "StyledKey_" + iTh}>{lpTextData.uFrame[iLp].popup.procReturnRefresh}</div></div>
                            </div>
                        )}
                    </div>

                    <div className="popupContentFull">
                        {(popupType === 'ok') && (
                            <div className="popupContent popupShadow OkMode" onClick={_ => {location.reload()}}>
                                <div className="popupContent__top"><div className="popupContent__top-title" dangerouslySetInnerHTML={{__html: (waitMessage.title ? waitMessage.title : 'Продукт подключен')}} /><div className="popupContent__top-closes" onClick={_ => {popupCloseAndClear()}} >+</div></div>
                                <div className="popupContent__productErr" dangerouslySetInnerHTML={{ __html: waitMessage.text }} />
                                <div className="popupContent__productConfirm PopupMode"><div className={"keyConnect " + "StyledKey_" + iTh}>{lpTextData.uFrame[iLp].popup.procReturnRefresh}</div></div>
                            </div>
                        )}
                    </div>
                </div>)}

                <div className={'MainPage__main-align ' + ((popup) ? 'popupBackground' : '')} id="MainPage__main-align" onClick={_ => {popupCloseAndClear()}}>
                    {(isStatic && document.location.pathname + '' === '/') && <div className="MainPageFooter">
                        <div className="MainPageHead__desktop">
                            <div className="MainPageFooter__lk">
                                <a target="_top" href="//www.wifire.ru/pay"><div className="old-icon-icons-41" /><div className="headerWrapMenu1Menu1-text">{lpTextData.uFrame[iLp].nBlock.aPay}</div></a>
                                <a target="_top" href="https://my.wifire.ru/#services/antivirus"><div className="old-icon-icons-42" /><div className="headerWrapMenu1Menu1-text">{lpTextData.uFrame[iLp].nBlock.lk}</div></a>
                            </div>
                            <div className="MainPageFooter__menu">
                                <div><a className="MainPageFooter__menuLogo" target="_top" href="https://wifire.ru"><div className="wifireLogo" /></a></div>
                                <div><div className="bgOrange" /><a target="_top" href="https://wifire.ru/promotions">{lpTextData.uFrame[iLp].nBlock.prom}</a></div>
                                <div><div className="bgOrange" /><a target="_top" href="https://wifire.ru/wifiretv">{lpTextData.uFrame[iLp].nBlock.tv1}<br /> {lpTextData.uFrame[iLp].nBlock.tv2}</a></div>
                                <div><div className="bgOrange" /><a target="_top" href="https://wifire.ru/wifirehome">{lpTextData.uFrame[iLp].nBlock.hi1}<br /> {lpTextData.uFrame[iLp].nBlock.hi2}</a></div>
                                <div><div className="bgOrange" /><a target="_top" href="https://wifire.ru/wifiremobile/forever">{lpTextData.uFrame[iLp].nBlock.mi1}<br /> {lpTextData.uFrame[iLp].nBlock.mi2}</a></div>
                                <div><div className="bgOrange" /><a target="_top" href="https://wifire.ru/wifirevas">{lpTextData.uFrame[iLp].nBlock.re1}<br /> {lpTextData.uFrame[iLp].nBlock.re2}</a></div>
                                <div className="MainPageFooter__menuPhone"><a href="tel:+78005508888">8 (800) 550-88-88</a></div>
                            </div>
                        </div>
                        <div className="MainPageHead__mobile">
                            <div onClick={e => setMMenu(!mMenu)}><div className="old-icon-icons-43" /><div><a target="_top" href="https://wifire.ru/"><div className="wifireLogo" /></a></div></div>
                            {mMenu && <div className="MainPageHead__mobileItem">
                                <div><a target="_top" href="https://wifire.ru/promotions">{lpTextData.uFrame[iLp].nBlock.prom}</a></div>
                                <div><a target="_top" href="https://wifire.ru/wifiretv">{lpTextData.uFrame[iLp].nBlock.tv1} {lpTextData.uFrame[iLp].nBlock.tv2}</a></div>
                                <div><a target="_top" href="https://wifire.ru/wifirehome">{lpTextData.uFrame[iLp].nBlock.hi1} {lpTextData.uFrame[iLp].nBlock.hi2}</a></div>
                                <div><a target="_top" href="https://wifire.ru/wifiremobile/forever">{lpTextData.uFrame[iLp].nBlock.mi1} {lpTextData.uFrame[iLp].nBlock.mi2}</a></div>
                                <div><a target="_top" href="https://wifire.ru/wifirevas">{lpTextData.uFrame[iLp].nBlock.re1} {lpTextData.uFrame[iLp].nBlock.re2}</a></div>
                                <div><a target="_top" href="https://wifire.ru/pay"><div className="old-icon-icons-41" /><div>{lpTextData.uFrame[iLp].nBlock.aPay}</div></a></div>
                                <div><a target="_top" href="https://my.wifire.ru/#services/antivirus"><div className="old-icon-icons-42" /><div>{lpTextData.uFrame[iLp].nBlock.lk}</div></a></div>
                            </div>}
                        </div>
                    </div>}

                    {
                        (textData && textData.data && textData.data.subscriptions && Object.keys(textData.data.subscriptions).length > 0) && <div className="oneBlock">
                            <div className="MainPage__filters">
                                <div className="Filters__main-name" dangerouslySetInnerHTML={{__html: lpTextData.uFrame[iLp].mainBlocks.bSubs }} />
                                <div className="FrameProductsList">
                                    {Object.keys(textData.data.subscriptions).map((item, i) => {if (textData.data.subscriptions[item].status + '' === 'active') {return <FrameProductCard key={i} typex="subs" item={item} num={i} />}return null})}
                                    {Object.keys(textData.data.subscriptions).map((item, i) => {if (textData.data.subscriptions[item].status + '' !== 'active') {return <FrameProductCard key={i} typex="subs" item={item} num={i} />}return null})}
                                </div>
                            </div>
                        </div>
                    }

                    {
                        (textData && textData.data && textData.data.products && Object.keys(textData.data.products).length > 0) && <div className="oneBlock">
                            <div className="MainPage__filters">
                                <div className="Filters__main-name" dangerouslySetInnerHTML={{__html: lpTextData.uFrame[iLp].mainBlocks.bHit }} />
                                <div className="FrameProductsHitList">
                                    {Object.keys(textData.data.products).map((item, i) => {if (textData.data.products[item].is_hit + '' === '1') {return <FrameProductHitCard key={i} item={item} num={i} />;}return null})}
                                </div>
                            </div>
                        </div>
                    }

                    {
                        (textData && textData.data && textData.data.products && Object.keys(textData.data.products).length > 0) && <div className="oneBlock">
                            <div className="MainPage__filters">
                                <div className="Filters__main-name" dangerouslySetInnerHTML={{__html: lpTextData.uFrame[iLp].mainBlocks.bProd }} />
                                <div className="FrameProductsList">
                                    {Object.keys(textData.data.products).map((item, i) => {return <FrameProductCard key={i} typex="product" item={item} num={i} />})}
                                </div>
                            </div>
                        </div>
                    }

                    {
                        PARTNER_OPT.offerBottom.title !== '' && <div className="oneBlock"><u><a target="_blank" href={(PARTNER_OPT.offerBottom.url.substring(0, 5) === 'https' ? '' : document.location.origin) + PARTNER_OPT.offerBottom.url}>{PARTNER_OPT.offerBottom.title}</a></u></div>
                    }

                    {(isStatic && document.location.pathname + '' === '/') && <div className="MainPageFooter">
                        <div className="MainPage__main-align">
                            <div className="MainPageFooter__links">
                                <div className="MainPageFooter__linksCollum">
                                    <div><a target="_top" href="https://www.wifire.ru/tv">{lpTextData.uFrame[iLp].nBlock.wtv}</a></div>
                                </div>
                                <div className="MainPageFooter__linksCollum">
                                    <div><a target="_top" href="https://www.wifire.ru/mobile">{lpTextData.uFrame[iLp].nBlock.mi1} {lpTextData.uFrame[iLp].nBlock.mi2}</a></div>
                                    <div><a target="_top" href="https://www.wifire.ru/mobile/info">{lpTextData.uFrame[iLp].nBlock.lte}</a></div>
                                    <div><a target="_top" href="https://www.wifire.ru/mobile/activate/">{lpTextData.uFrame[iLp].nBlock.how}</a></div>
                                    <div><a target="_top" href="https://www.wifire.ru/mobile/coverage">{lpTextData.uFrame[iLp].nBlock.vMap}</a></div>
                                    <div><a target="_top" href="https://www.wifire.ru/mobile/">{lpTextData.uFrame[iLp].nBlock.hLte}</a></div>
                                </div>
                                <div className="MainPageFooter__linksCollum">
                                    <div><a target="_top" href="https://www.wifire.ru/wifirehome">{lpTextData.uFrame[iLp].nBlock.hi1} {lpTextData.uFrame[iLp].nBlock.hi2}</a></div>
                                </div>
                                <div className="MainPageFooter__linksCollum">
                                    <div><a target="_top" href="https://www.wifire.ru/packets">{lpTextData.uFrame[iLp].nBlock.sPack}</a></div>
                                </div>
                                <div className="MainPageFooter__linksCollum">
                                    <div><a target="_top" href="https://corp.netbynet.ru/docs/">{lpTextData.uFrame[iLp].nBlock.sDoc}</a></div>
                                </div>
                                <div className="MainPageFooter__linksCollum">
                                    <div><a target="_top" href="https://my.wifire.ru/">{lpTextData.uFrame[iLp].nBlock.lk}</a></div>
                                </div>
                                <div className="MainPageFooter__linksCollum">
                                    <div><a target="_blank" href="https://vk.com/wifireexpert" className="old-icon-icons-45" /></div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        )}
    </div>)
}

export default App
