import React from 'react'
import {Route, Routes} from 'react-router-dom'
import UniFrame from './uFrame'
import UniBackOffice from "./uBo"
import 'css-style'
import './App.css'
import {urlAlert} from "api-methods"

function Root() {

    if (document.location.pathname.replace(/[/_A-Za-z]+/g, "") !== "") {urlAlert('url-pathname-char').then()}
    if (document.location.search.replace(/[?=&A-Za-z0-9]+/g, "") !== "") {urlAlert('url-search-char').then()}

    return <Routes>
        <Route path="/manager/*" element={<UniBackOffice />} />
        <Route path="/manager" element={<UniBackOffice />} />
        <Route path="/remotes/safekids/" element={<UniFrame/>} />
        <Route path="/remotes/safekids" element={<UniFrame/>} />
        <Route path="/remotes/antivirus" element={<UniFrame/>} />
        <Route path="/shopwindow/wifire/" element={<UniFrame/>} />
        <Route path="/shopwindow/wifire" element={<UniFrame/>} />
        <Route path="/shopwindow/catalog_av/api/frontend/static" element={<UniFrame/>} />
        <Route path="/" element={<UniFrame/>} />
    </Routes>
}

export default Root
